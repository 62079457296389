import React, { FC } from 'react'

import {
  FindFlaggedSentimentEmailsByMemberQuery,
  FindFlaggedSentimentEmailsByMemberQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as EmptyData } from 'src/components/Library/Empty/Empty'
import { default as LoadingSpinner } from 'src/components/Library/Loading'
import FlaggedEmailsList from 'src/components/Settings/SentimentAnalysis/FlaggedEmailsList'

export const QUERY = gql`
  query FindFlaggedSentimentEmailsByMemberQuery {
    memberFlaggedEmails: landlordFlaggedEmailsByMembers {
      membershipId
      emails {
        id
        feedback
        adminFeedback
        membershipId
        reviewedByMembershipId
        reviewedDate
        createdAt
        originalSentiment
        landLordEmail {
          ...LandlordEmailFields
        }
        reviewedBy {
          id
          user {
            id
            name
            email
            avatarUrl
            position
          }
        }
      }
    }
    memberships: membershipsByClient(activeOnly: true) {
      id
      role
      memberPositions {
        id
        name
      }
      user {
        id
        name
        email
        avatarUrl
        position
      }
    }
  }
`

export const Loading = () => (
  <div className={'mt-4 h-full w-full'}>
    <LoadingSpinner />
  </div>
)

export const Empty = () => (
  <div className={'mt-4 h-full w-full'}>
    <EmptyData
      title={'No Data Found'}
      description={'There are no emails that have been flagged for review'}
    />
  </div>
)

export const Failure = ({
  error,
}: CellFailureProps<FindFlaggedSentimentEmailsByMemberQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

type Props = CellSuccessProps<FindFlaggedSentimentEmailsByMemberQuery>

export type Membership = Props['memberships'][0]
export type StandardEmail = Props['memberFlaggedEmails'][0]['emails'][0]
export type MemberFlaggedEmails = Props['memberFlaggedEmails'][0]

export const Success: FC<Props> = ({ memberFlaggedEmails, memberships }) => {
  if (memberFlaggedEmails.length === 0) {
    return <Empty />
  }
  return (
    <FlaggedEmailsList
      flaggedEmailsList={memberFlaggedEmails}
      memberships={memberships}
    />
  )
}
