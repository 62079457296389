import { FC } from 'react'

import { FLAG_FEATURES, HUBSPOT_FORM_ID } from 'api/src/common/enums'

import { routes } from '@redwoodjs/router'
import { Metadata } from '@redwoodjs/web'

import FeatureDenied from 'src/components/Library/FeatureDenied/FeatureDenied'
import PageHeader from 'src/components/PageHeader/PageHeader'
import SettingsSentimentAnalysis from 'src/components/Settings/SentimentAnalysis/SettingsSentimentAnalysis/SettingsSentimentAnalysis'
import FeatureToggle from 'src/lib/hooks/LiveFeatureFlag/FeatureToggle'

import { SENTIMENT_ADMIN_TABS } from './types'

export type PageQueryTypes = Partial<
  Parameters<typeof routes.settingsSentimentAnalysisTab>[0]
>

const SettingsSentimentAnalysisPage: FC<PageQueryTypes> = (props) => {
  const tabToUpper = (props.tab ?? '').toUpperCase()
  const selectedTab =
    SENTIMENT_ADMIN_TABS[tabToUpper] ?? SENTIMENT_ADMIN_TABS.OVERVIEW

  return (
    <>
      <Metadata
        title="Sentiment Analysis Admin"
        description="Sentiment Analysis Admin page"
      />
      <PageHeader title="Sentiment Analysis Admin" />
      <FeatureToggle
        feature={FLAG_FEATURES.SENTIMENT_ANALYSIS}
        InactiveComponent={
          <FeatureDenied hubspotFormId={HUBSPOT_FORM_ID.SENTIMENT_ANALYSIS} />
        }
      >
        <div className="min-h-[calc(100vh-80px)] w-full bg-white">
          <div className="mx-auto w-full p-6">
            <SettingsSentimentAnalysis tab={selectedTab} />
          </div>
        </div>
      </FeatureToggle>
    </>
  )
}

export default SettingsSentimentAnalysisPage
