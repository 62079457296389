import { FC, useEffect, useMemo, useState } from 'react'

import type { Landlord } from 'types/graphql'

import { default as Empty } from 'src/components/Library/Empty'
import { SentimentAnalysisScoreGridProps } from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'
import SentimentAnalysisLandlordAccordion from 'src/components/SentimentAnalysis/SentimentAnalysisList/SentimentAnalysisLandlordAccordion'
import {
  filterESIRecords,
  formatESIRecords,
  sortActiveESIRecords,
} from 'src/components/SentimentAnalysis/SentimentAnalysisUtils'

import type { FindSettingsSentimentAnalysisMembership } from '../SettingsSentimentAnalysisScoreGridCell'

const searchLandlordsInMembership = (
  membership: FindSettingsSentimentAnalysisMembership & {
    memberDetails: Landlord
  },
  searchText: string,
) => {
  const filteredLandlords =
    membership.landlordsWithEmailsAndSentimentScores.filter((landlord) =>
      landlord.name.toLowerCase().includes(searchText),
    )

  if (filteredLandlords.length > 0) {
    return {
      ...membership,
      landlordsWithEmailsAndSentimentScores: filteredLandlords,
    }
  }
  return null
}

const SentimentAnalysisScoreGrid: FC<SentimentAnalysisScoreGridProps> = ({
  memberships,
  sortByValues,
  filterByMember,
  filterDateRange,
  filterESIRating,
  filterByFlag,
  filterByClientStatus,
  debouncedSearchText,
}) => {
  const [activeMemberships, setActiveMemberships] = useState(
    filterESIRecords(
      formatESIRecords(memberships),
      filterByMember,
      sortByValues,
      filterESIRating,
      filterByFlag,
      filterByClientStatus,
    ),
  )
  const [isReady, setIsReady] = useState(false)

  useEffect(() => {
    if (isReady) {
      setActiveMemberships(
        sortActiveESIRecords(activeMemberships, sortByValues),
      )
    }
  }, [sortByValues])

  useEffect(() => {
    setIsReady(true)
  }, [activeMemberships])

  const filteredActiveMemberships = useMemo(() => {
    if (debouncedSearchText.length === 0) return activeMemberships
    const searchText = debouncedSearchText.toLowerCase()
    return activeMemberships
      .map((membership) => searchLandlordsInMembership(membership, searchText))
      .filter(Boolean)
  }, [activeMemberships, debouncedSearchText])

  return (
    <div className="relative flex flex-col gap-2">
      {debouncedSearchText.length === 0 && activeMemberships?.length === 0 && (
        <Empty title="No analysis results found" className="mt-10" />
      )}
      {debouncedSearchText.length > 0 &&
        filteredActiveMemberships?.length === 0 && (
          <Empty title="No search results found" className="mt-10" />
        )}
      {filteredActiveMemberships?.length > 0 &&
        filteredActiveMemberships?.map((member) => {
          return (
            <SentimentAnalysisLandlordAccordion
              key={member.id}
              membership={member}
              filterDateRange={filterDateRange}
              filterESIRating={filterESIRating}
              filterByFlag={filterByFlag}
              filterByClientStatus={filterByClientStatus}
              debouncedSearchText={debouncedSearchText}
            />
          )
        })}
    </div>
  )
}

export default SentimentAnalysisScoreGrid
