import { FC } from 'react'

import Switch from 'src/components/Library/Switch/Switch'

const SentimentAnalysisDataGridToggle: FC<{
  id: number
  isDisabled: boolean
  isInvited: boolean
  toggleUserAccess: (recordId: number, toggledValue: boolean) => void
}> = ({ id, isDisabled, isInvited, toggleUserAccess }) => {
  return (
    <Switch
      title={isDisabled ? 'Disabled' : 'Enabled'}
      disabled={!isInvited}
      checked={!isDisabled}
      onChange={() => {
        toggleUserAccess(id, !isDisabled)
      }}
    />
  )
}

export default SentimentAnalysisDataGridToggle
