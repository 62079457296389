import { useState } from 'react'

import {
  FindMembershipDetailsByClient,
  FindMembershipDetailsByClientVariables,
} from 'types/graphql'

import { useQuery } from '@redwoodjs/web'

import { SelectionType } from 'src/components/Library/SelectWithSort/SelectWithSort'
import {
  FilterByOption,
  SADateFilterOptions,
  SASortOptions,
  SentimentAnalysisRatingOptions,
  SentimentAnalysisFlagOptions,
  SentimentStatusSelectOptions,
} from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'
import SentimentAnalysisFilterBar from 'src/components/SentimentAnalysis/SentimentAnalysisList/SentimentAnalysisFilterBar'
import SettingsSentimentAnalysisScoreGridCell from 'src/components/Settings/SentimentAnalysis/SettingsSentimentAnalysisScoreGridCell'
import useDebounce from 'src/lib/hooks/UseDebounce'

import { MEMBERSHIPS_BY_CLIENT } from '../SettingsSentimentAnalysisQueries'

// Separate component so the query is not called on Tabs other than OVERVIEW
const FilterBar = () => {
  const [sortByValues, setSortByValues] = useState<SelectionType>({
    value: SASortOptions.esi,
    asc: true,
  })
  const [filterDateRange, setFilterDateRange] = useState<SADateFilterOptions>(
    SADateFilterOptions.WEEK,
  )
  const [filterByMember, setFilterByMember] = useState<FilterByOption[]>([])

  const [filterESIRating, setFilterESIRating] =
    useState<SentimentAnalysisRatingOptions>(SentimentAnalysisRatingOptions.ALL)

  const [selectedFlagFilter, setSelectedFlagFilter] =
    useState<SentimentAnalysisFlagOptions>(SentimentAnalysisFlagOptions.ALL)

  const [filterByClientStatus, setFilterByClientStatus] =
    useState<SentimentStatusSelectOptions>(SentimentStatusSelectOptions.ALL)

  const [membershipSelectOptions, setMembershipSelectOptions] = useState<
    FilterByOption[]
  >([])

  const [currentSearchText, setCurrentSearchText] = useState('')

  const debouncedSearchText = useDebounce(currentSearchText, 500)

  useQuery<
    FindMembershipDetailsByClient,
    FindMembershipDetailsByClientVariables
  >(MEMBERSHIPS_BY_CLIENT, {
    onCompleted: (data) => {
      if (data?.memberships?.length > 0) {
        const membershipOptions: FilterByOption[] = data.memberships.map(
          (membership) => {
            return {
              id: membership.id,
              name: membership.user.name,
              avatarUrl: membership.user.avatarUrl || '/',
            }
          },
        )

        setMembershipSelectOptions(membershipOptions)
      }
    },
  })

  return (
    <>
      <SentimentAnalysisFilterBar
        adminView
        sortByValues={sortByValues}
        setSortByValues={setSortByValues}
        filterDateRange={filterDateRange}
        setFilterDateRange={setFilterDateRange}
        filterByValues={filterByMember}
        setFilterByValues={setFilterByMember}
        filterESIRating={filterESIRating}
        setFilterESIRating={setFilterESIRating}
        filterByOptions={membershipSelectOptions}
        selectedFlagFilter={selectedFlagFilter}
        setSelectedFlagFilter={setSelectedFlagFilter}
        filterByClientStatus={filterByClientStatus}
        setFilterByClientStatus={setFilterByClientStatus}
        currentSearchText={currentSearchText}
        setCurrentSearchText={setCurrentSearchText}
      />
      <SettingsSentimentAnalysisScoreGridCell
        filterDateRange={filterDateRange}
        sortByValues={sortByValues}
        filterByMember={filterByMember}
        filterESIRating={filterESIRating}
        filterByFlag={selectedFlagFilter}
        filterByClientStatus={filterByClientStatus}
        debouncedSearchText={debouncedSearchText}
      />
    </>
  )
}

export default FilterBar
