import { FC, useState } from 'react'

import { useApolloClient } from '@apollo/client'
import { Tooltip } from '@mui/material'
import TextField from '@mui/material/TextField'
import {
  DataGrid,
  GridColDef,
  GridFilterModel,
  GridRowSelectionModel,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid'
import {
  CreateSentimentAnalysisPermissionMutation,
  CreateSentimentAnalysisPermissionMutationVariables,
  UpdateSentimentAnalysisPermissionMutation,
  UpdateSentimentAnalysisPermissionMutationVariables,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Autocomplete from 'src/components/Library/Autocomplete/Autocomplete'
import Button from 'src/components/Library/Button/Button'
import { SentimentAnalysisUserStatus } from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'
import { MembershipGroups } from 'src/components/Settings/SentimentAnalysis/SettingsSentimentAnalysisCell/SettingsSentimentAnalysisCell'

import { Memberships } from '../SettingsSentimentAnalysisCell'
import {
  CREATE_SA_PERMISSION,
  UPDATE_SA_PERMISSION,
} from '../SettingsSentimentAnalysisQueries'

import SentimentAnalysisDataGridLabel from './SentimentAnalysisDataGridLabel'
import SentimentAnalysisDataGridToggle from './SentimentAnalysisDataGridToggle'

interface Props {
  memberships: Memberships
  membershipGroups: MembershipGroups
}

const SentimentAnalysisDataGrid: FC<Props> = ({
  memberships,
  membershipGroups,
}) => {
  const apolloClient = useApolloClient()
  const [isLoading, setIsLoading] = useState(false)
  const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>()
  const [selectedGroupFilters, setSelectedGroupFilters] =
    useState<MembershipGroups[]>()
  const [filterModel, setFilterModel] = useState<GridFilterModel>({
    items: [],
  })
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    email: false,
    id: false,
  } as Record<GridColDef['field'], boolean>)

  const [createSAPermission] = useMutation<
    CreateSentimentAnalysisPermissionMutation,
    CreateSentimentAnalysisPermissionMutationVariables
  >(CREATE_SA_PERMISSION, {
    onError: (error, clientOptions) => {
      const variables =
        clientOptions.variables as CreateSentimentAnalysisPermissionMutationVariables
      const memberId = variables.input.membershipId

      if (memberId && Number.isInteger(memberId)) {
        const member = memberships.find((m) => m.id === memberId)
        toast.error(`Failed to invite ${member.user.name}`, {
          duration: 5000,
          className: 'flex-column',
        })
      } else {
        toast.error('Failed to Invite for a Member', {
          duration: 5000,
          className: 'flex-column',
        })
      }

      // Need to throw error to the final toast in inviteUsers is called with the correct message
      throw error
    },
  })

  const inviteUsers = async () => {
    setIsLoading(true)

    const createPromises = selectionModel.map((membershipId) => {
      return createSAPermission({
        variables: {
          sendInvite: true,
          input: {
            membershipId: membershipId.valueOf() as number,
            disabled: false,
            invited: true,
          },
        },
      })
    })

    const result = await Promise.allSettled(createPromises)

    await apolloClient.refetchQueries({
      include: ['FindSettingsSentimentAnalysisQuery'],
    })

    setIsLoading(false)

    const someRejected = result.some((r) => r.status === 'rejected')
    const someFulfilled = result.some((r) => r.status === 'fulfilled')

    if (!someRejected) {
      toast.success('Invitations sent successfully', {
        duration: 5000,
        className: 'flex-column',
      })
    } else if (someFulfilled) {
      toast.error('Some invitations failed to send', {
        duration: 5000,
        className: 'flex-column',
      })
    }
  }

  const [updateSAPermission] = useMutation<
    UpdateSentimentAnalysisPermissionMutation,
    UpdateSentimentAnalysisPermissionMutationVariables
  >(UPDATE_SA_PERMISSION, {
    onCompleted: () => {
      setIsLoading(false)
    },
    onError: (error) => {
      setIsLoading(false)

      toast.error(error.message, {
        duration: 5000,
        className: 'flex-column',
      })
    },
    refetchQueries: ['FindSettingsSentimentAnalysisQuery'],
    awaitRefetchQueries: true,
  })

  const toggleUserAccess = async (recordId: number, toggledValue) => {
    setIsLoading(true)

    await updateSAPermission({
      variables: {
        id: recordId,
        input: {
          disabled: toggledValue,
        },
      },
    })
  }

  const accessColumns: GridColDef<AccessRow>[] = [
    {
      field: 'id',
      type: 'number',
    },
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      minWidth: 120,
      disableColumnMenu: false,
      filterable: true,
      renderCell: (param) => (
        <Tooltip title={param.row.email}>
          <div>{param.row.name}</div>
        </Tooltip>
      ),
    },
    {
      field: 'email',
    },
    {
      field: 'position',
      headerName: 'Position',
      flex: 1,
      minWidth: 120,
      disableColumnMenu: false,
      filterable: true,
    },
    {
      field: 'status',
      headerName: 'Status',
      flex: 1,
      minWidth: 100,
      renderCell: (param) => (
        <SentimentAnalysisDataGridLabel label={param.row.status} />
      ),
    },
    {
      field: 'access',
      headerName: 'Access',
      sortable: true,
      minWidth: 120,
      filterable: false,
      valueGetter: (_value, row) => {
        // For sorting purposes
        return !row.isInvited ? 'A' : row.isDisabled ? 'B' : 'C'
      },
      renderCell: (param) => (
        <>
          {param.row.isInvited && (
            <SentimentAnalysisDataGridToggle
              id={param.row.permissionId}
              isDisabled={param.row.isDisabled}
              isInvited={param.row.isInvited}
              toggleUserAccess={toggleUserAccess}
            />
          )}
        </>
      ),
    },
  ]

  type AccessRow = (typeof accessRows)[0]
  const accessRows = memberships?.map((membership) => {
    // Get membership access context
    const isInvited = membership?.sentimentAnalysisPermission?.invited ?? false
    const isDisabled =
      membership?.sentimentAnalysisPermission?.disabled ?? false

    const isActive =
      membership?.landlordsWithEmailsAndSentimentScores?.length > 0

    // Set the membership status
    let status = SentimentAnalysisUserStatus.notInvited
    if (isInvited) status = SentimentAnalysisUserStatus.invited
    if (isActive) status = SentimentAnalysisUserStatus.active
    if (isDisabled) status = SentimentAnalysisUserStatus.disabled

    const permissionId = membership?.sentimentAnalysisPermission?.id ?? null

    return {
      /** Hubs Membership Id */
      id: membership.id,
      /** User name */
      name: membership.user.name,
      email: membership.user.email,
      position: membership.user.position,
      status: status,
      isInvited,
      isDisabled,
      permissionId,
    }
  })

  const onFilterGroups = (filterValue: number[]) => {
    if (filterValue[0] === 0) {
      setFilterModel({
        items: [],
      })
      return
    }
    setFilterModel({
      items: [
        {
          field: 'id',
          operator: 'isAnyOf',
          value: filterValue,
        },
      ],
    })
  }

  const GridToolbar = () => {
    return (
      <div className="justify flex items-center gap-2 border-b p-1">
        <div className="mt-1 flex items-center">
          <GridToolbarQuickFilter
            variant={'outlined'}
            // eslint-disable-next-line jsx-a11y/no-autofocus
            autoFocus
            data-testid="member-management-search"
            size={'small'}
            debounceMs={500}
          />
        </div>
        <div className="flex items-center">
          <Autocomplete
            id="tags-outlined"
            className="w-full max-w-[400px] rounded bg-white"
            size="small"
            options={membershipGroups}
            filterSelectedOptions
            getOptionLabel={(option) => option?.name || ''}
            renderInput={(params) => (
              <TextField
                className={'w-[200px]'}
                {...params}
                placeholder="Filter By Group"
              />
            )}
            value={selectedGroupFilters}
            onChange={(_: any, newValue: any) => {
              if (newValue === null) {
                onFilterGroups([0])
                setSelectedGroupFilters(undefined)
              } else {
                if (newValue.memberships && newValue.memberships.length > 0) {
                  onFilterGroups(
                    newValue.memberships.map(
                      (memberToGroup) => memberToGroup.membership.id,
                    ),
                  )
                } else if (newValue?.memberships?.length === 0) {
                  onFilterGroups([-1])
                }
                setSelectedGroupFilters(newValue)
              }
            }}
          />
        </div>
        <div className="flex h-10 items-center">
          <Button
            variant="outlined"
            color="primary"
            className={'h-full'}
            disabled={!selectionModel?.length || isLoading}
            onClick={inviteUsers}
          >
            Invite Selected
          </Button>
        </div>
      </div>
    )
  }

  return (
    <div className="py-6">
      <DataGrid<AccessRow>
        sx={{
          '& .MuiDataGrid-columnHeader:focus, .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
        }}
        slots={{
          toolbar: GridToolbar,
        }}
        pageSizeOptions={[10, 20, 30]}
        initialState={{
          pagination: { paginationModel: { pageSize: 20 } },
          sorting: {
            sortModel: [{ field: 'status', sort: 'asc' }],
          },
        }}
        loading={isLoading}
        autoHeight
        rows={accessRows}
        columns={accessColumns}
        checkboxSelection={true}
        isRowSelectable={(params) => !params.row.isInvited}
        getRowId={(row) => row.id}
        onRowSelectionModelChange={setSelectionModel}
        columnVisibilityModel={columnVisibilityModel}
        onColumnVisibilityModelChange={(newModel) =>
          setColumnVisibilityModel(newModel)
        }
        filterModel={filterModel}
        onFilterModelChange={(newFilterModel) => setFilterModel(newFilterModel)}
      />
    </div>
  )
}

export default SentimentAnalysisDataGrid
