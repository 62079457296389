import React, { useEffect, useState, type FC } from 'react'

import {
  ArchiveBoxIcon,
  ExclamationTriangleIcon,
} from '@heroicons/react/24/outline'
import {
  Avatar,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabel,
  TextareaAutosize,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material'
import dayjs from 'dayjs'
import {
  RejectFlagEmail,
  RejectFlagEmailVariables,
  UpdateESIRating,
  UpdateESIRatingVariables,
  UpdateLandlordEmailSentimentScoreInput,
} from 'types/graphql'

import { useMutation } from '@redwoodjs/web'
import { toast } from '@redwoodjs/web/toast'

import Button from 'src/components/Library/Button'
import { SentimentAnalysisLabelThemes } from 'src/components/SentimentAnalysis/SentimentAnalysisHelper'
import SentimentAnalysisBadge from 'src/components/SentimentAnalysis/SentimentAnalysisList/SentimentAnalysisBadge'
import SentimentAnalysisEmailFlagStatusChip from 'src/components/SentimentAnalysis/SentimentAnalysisList/SentimentAnalysisEmailFlagStatusChip'
import { getRatingStatus } from 'src/components/SentimentAnalysis/SentimentAnalysisUtils'
import {
  Membership,
  type StandardEmail,
} from 'src/components/Settings/SentimentAnalysis/FlaggedSentimentEmailsByMemberCell/FlaggedSentimentEmailsByMemberCell'

import {
  REJECT_FLAGGED_EMAIL,
  UPDATE_ESI_RATING,
} from './SettingsSentimentAnalysisQueries'

const HEALTH_RATINGS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]

interface UserCommentProps {
  feedback?: string
  member?: Membership
  flaggedEmail?: StandardEmail
  showFlaggedAt?: boolean
}

const UserComment: FC<UserCommentProps> = ({
  feedback,
  member,
  flaggedEmail,
}) => {
  return (
    <div className="rounded-lg border bg-white p-2 pb-4 text-base">
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center">
          <Avatar className={'mr-3 h-8 w-8'} src={member?.user?.avatarUrl} />
          <p className="mr-3 inline-flex items-center text-sm font-semibold text-gray-900">
            {member?.user?.name}
          </p>

          <p className="text-sm text-gray-500">
            Flagged At:{' '}
            {dayjs(flaggedEmail?.createdAt).format('DD/MM/YYYY hh:mma')}{' '}
          </p>
        </div>
      </div>
      <p className="text-gray-500 dark:text-gray-400">
        {feedback ? feedback : 'No feedback provided'}
      </p>
    </div>
  )
}

interface AdminCommentProps {
  feedback: string
  member: Membership
  email: StandardEmail
}

const AdminComment: FC<AdminCommentProps> = ({ feedback, member, email }) => {
  return (
    <div className="rounded-lg border bg-white p-2 pb-4 text-base">
      <div className="mb-2 flex items-center justify-between">
        <div className="flex items-center">
          <Avatar className={'mr-3 h-8 w-8'} src={member?.user?.avatarUrl} />
          <p className="mr-3 inline-flex items-center text-sm font-semibold text-gray-900">
            {member?.user?.name}
          </p>

          <p className="text-sm text-gray-500">
            Admin Reviewed:{' '}
            {dayjs(email?.reviewedDate).format('DD/MM/YYYY hh:mma')}{' '}
          </p>
        </div>
      </div>
      <p className="pt-2 text-gray-500 dark:text-gray-400">
        {feedback ? feedback : 'No feedback provided'}
      </p>
    </div>
  )
}

interface FlaggedEmailReviewModalProps {
  email: StandardEmail
  isOpen: boolean
  onClose: () => void
  member: Membership
  reviewedByMember: Membership
  archivedView?: boolean
}

const FlaggedEmailReviewModal: FC<FlaggedEmailReviewModalProps> = ({
  email,
  onClose,
  isOpen,
  member,
  reviewedByMember,
  archivedView = false,
}) => {
  const [approveLoading, setApproveLoading] = useState(false)
  const [rejectLoading, setRejectLoading] = useState(false)

  const [disableRejectButton, setDisableRejectButton] = useState(false)
  const [disableApproveButton, setDisableApproveButton] = useState(false)

  const [adminFeedback, setAdminFeedback] = useState<string>(
    email?.adminFeedback || '',
  )

  const labelTheme = getRatingStatus(
    !archivedView
      ? email.originalSentiment?.['nps']
      : email?.landLordEmail?.sentimentScores['nps'],
  )

  const [updateScore, setUpdateScore] =
    useState<UpdateLandlordEmailSentimentScoreInput>(
      !archivedView
        ? (email?.landLordEmail
            ?.sentimentScores as UpdateLandlordEmailSentimentScoreInput)
        : (email?.originalSentiment as UpdateLandlordEmailSentimentScoreInput),
    )

  const [updateESIRating] = useMutation<
    UpdateESIRating,
    UpdateESIRatingVariables
  >(UPDATE_ESI_RATING, {
    onCompleted: () => {
      toast.success('Health rating updated')
      setApproveLoading(false)
      onClose()
    },
    onError: () => {
      toast.error('Failed to update Health rating')
      setApproveLoading(false)
    },
    refetchQueries: ['FindFlaggedSentimentEmailsByMemberQuery'],
    awaitRefetchQueries: true,
  })

  const [rejectEmail] = useMutation<RejectFlagEmail, RejectFlagEmailVariables>(
    REJECT_FLAGGED_EMAIL,
    {
      onCompleted: () => {
        toast.success('Email rejected')
        setRejectLoading(false)
        onClose()
      },
      onError: () => {
        toast.error('Failed to reject email')
        setRejectLoading(false)
      },
      refetchQueries: ['FindFlaggedSentimentEmailsByMemberQuery'],
      awaitRefetchQueries: true,
    },
  )

  const currentNpsScore = email?.landLordEmail?.sentimentScores?.['nps'] ?? null
  const isScoreUnmodified = (current: number | null, updated: number | null) =>
    current === updated

  const canReject = (feedback: string, isScoreModified: boolean) =>
    Boolean(feedback.trim()) && !isScoreModified

  const canApprove = (feedback: string, isScoreModified: boolean) =>
    Boolean(feedback.trim()) && isScoreModified

  useEffect(() => {
    const scoreModified = !isScoreUnmodified(
      currentNpsScore,
      updateScore?.nps ?? null,
    )

    setDisableRejectButton(!canReject(adminFeedback, scoreModified))
    setDisableApproveButton(!canApprove(adminFeedback, scoreModified))
  }, [adminFeedback, updateScore, currentNpsScore])

  return (
    <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth={'sm'}>
      <DialogTitle>
        <div
          className={
            'flex flex-row items-center justify-between gap-4 text-gray-500'
          }
        >
          <div className="flex items-center">
            {archivedView && <ArchiveBoxIcon className={'mr-2 h-6 w-6'} />}
            <span className="text-md -mb-1 font-bold uppercase">
              Email:{' '}
              {dayjs(email?.landLordEmail?.receivedAt).format(
                'DD/MM/YYYY hh:mma',
              )}
            </span>
          </div>
          <SentimentAnalysisEmailFlagStatusChip
            status={email?.landLordEmail?.flaggedStatus}
            className="ml-3"
          />
        </div>
      </DialogTitle>
      <DialogContent>
        <div className="flex flex-col pt-2">
          <div className="mb-3 flex items-center justify-between">
            <FormLabel
              id="health-rating"
              sx={{
                textTransform: 'uppercase',
                marginBottom: '-0.5rem',
              }}
            >
              {archivedView ? 'Previous' : 'Select'} Health Rating
            </FormLabel>
            <SentimentAnalysisBadge
              title="Current Health: "
              value={
                email?.landLordEmail?.sentimentScores['nps'] ||
                email?.landLordEmail?.csiRating
              }
              variant={SentimentAnalysisLabelThemes[labelTheme]}
              className="mb-1 text-center text-xs"
            />
          </div>
          <ToggleButtonGroup
            aria-label="Health rating selection"
            exclusive
            onChange={(_, value) => {
              if (value !== null) {
                setUpdateScore((prev) => ({ ...prev, nps: value as number }))
              }
            }}
            disabled={approveLoading || rejectLoading || archivedView}
            value={updateScore?.nps}
            style={{ width: '100%' }}
          >
            {HEALTH_RATINGS.map((score) => {
              return (
                <ToggleButton
                  key={score}
                  value={score}
                  selected={updateScore?.nps === score}
                  style={{ flex: 1 }}
                >
                  {score}
                </ToggleButton>
              )
            })}
          </ToggleButtonGroup>
        </div>

        <div className="mt-6">
          {archivedView ? (
            <div className="flex flex-col gap-4">
              <UserComment
                feedback={email?.feedback}
                member={member}
                flaggedEmail={email}
                showFlaggedAt
              />
              <AdminComment
                feedback={email?.adminFeedback}
                member={reviewedByMember}
                email={email}
              />
            </div>
          ) : (
            <>
              <div className={'flex flex-col gap-4'}>
                <UserComment feedback={email.feedback} member={member} />

                <TextareaAutosize
                  className={'w-full rounded-md border p-2'}
                  minRows={3}
                  placeholder="Enter feedback to the user here..."
                  onChange={(e) => {
                    setAdminFeedback(e.target.value)
                  }}
                />
              </div>
              <div className="flex items-center pt-2">
                <ExclamationTriangleIcon
                  className={'mr-2 h-5 w-5 text-orange-500'}
                />
                <p className="text-xs text-gray-500">
                  Rejections require an unmodified score, approvals require a
                  modified score. <br />
                  Feedback is also required.
                </p>
              </div>
            </>
          )}
        </div>
      </DialogContent>
      <DialogActions className="flex flex-row pb-5">
        {archivedView ? (
          <Button
            fullWidth={false}
            variant={'outlined'}
            color={'secondary'}
            onClick={onClose}
            className="mr-4"
          >
            Close
          </Button>
        ) : (
          <div className={'flex w-full justify-between px-4'}>
            <Button
              fullWidth={false}
              variant={'outlined'}
              color={'secondary'}
              onClick={onClose}
            >
              Cancel
            </Button>

            <div>
              {!archivedView && (
                <div className={'flex w-full justify-end gap-2'}>
                  <Button
                    fullWidth={false}
                    color={'error'}
                    disabled={disableRejectButton}
                    loading={rejectLoading}
                    onClick={() => {
                      setRejectLoading(true)
                      rejectEmail({
                        variables: {
                          id: email?.id,
                          input: { adminFeedback: adminFeedback },
                        },
                      })
                    }}
                  >
                    Reject
                  </Button>
                  <Button
                    fullWidth={false}
                    loading={approveLoading}
                    disabled={disableApproveButton}
                    onClick={() => {
                      setApproveLoading(true)
                      updateESIRating({
                        variables: {
                          id: email?.landLordEmail?.id,
                          input: updateScore,
                          adminFeedback: adminFeedback,
                          flaggedStatus: 'APPROVED',
                        },
                      })
                    }}
                  >
                    Approve
                  </Button>
                </div>
              )}
            </div>
          </div>
        )}
      </DialogActions>
    </Dialog>
  )
}

export default FlaggedEmailReviewModal
