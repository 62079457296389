import * as React from 'react'

import type {
  FindSettingsSentimentAnalysisQuery,
  FindSettingsSentimentAnalysisQueryVariables,
} from 'types/graphql'

import type { CellFailureProps, CellSuccessProps } from '@redwoodjs/web'

import { default as LoadingSpinner } from 'src/components/Library/Loading'

import SentimentAnalysisDataGrid from '../SentimentAnalysisDataGrid/SentimentAnalysisDataGrid'

export const beforeQuery = (props) => {
  return {
    variables: props,
    fetchPolicy: 'no-cache',
    nextFetchPolicy: 'no-cache',
  }
}

export const QUERY = gql`
  query FindSettingsSentimentAnalysisQuery {
    memberships: membershipsByClient(activeOnly: true) {
      id
      user {
        name
        email
        avatarUrl
        position
      }
      membershipGroups {
        membershipGroup {
          id
          name
        }
      }
      landlordsWithEmailsAndSentimentScores {
        id
      }
      sentimentAnalysisPermission {
        id
        disabled
        invited
      }
    }
    membershipGroups {
      id
      name
      memberships {
        membership {
          id
        }
      }
    }
  }
`

export const Loading = () => (
  <div className="py-20">
    <LoadingSpinner />
  </div>
)

export const Empty = () => (
  <SentimentAnalysisDataGrid memberships={[]} membershipGroups={[]} />
)

export const Failure = ({
  error,
}: CellFailureProps<FindSettingsSentimentAnalysisQueryVariables>) => (
  <div style={{ color: 'red' }}>Error: {error?.message}</div>
)

export type Memberships = FindSettingsSentimentAnalysisQuery['memberships']
export type MembershipGroups =
  FindSettingsSentimentAnalysisQuery['membershipGroups']

export const Success = ({
  memberships,
  membershipGroups,
}: CellSuccessProps<
  FindSettingsSentimentAnalysisQuery,
  FindSettingsSentimentAnalysisQueryVariables
>) => {
  return (
    <SentimentAnalysisDataGrid
      memberships={memberships}
      membershipGroups={membershipGroups}
    />
  )
}
